import React from 'react'
import GoogleMapReact from 'google-map-react';

import mapStyle from './mapStyle'

export default function Map () {

	const eyePosition = {
		center: {
			lat: 52.3202,
			lng: 1.1462
		},
		zoom: 17.3
	}

	return (
		// Important! Always set the container height explicitly
		<div style={{ height: '65vh', width: '100%' }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: process.env.GOOGLE_MAP_KEY }}
				options={{ styles: mapStyle }}
				defaultCenter={eyePosition.center}
				defaultZoom={eyePosition.zoom} />
		</div>
	)
}