import React from 'react'

export default function ContactInfo () {

	return (
		<section className="contact-info">
			<div className="contact-info__container wrapper">
				<div className="contact-info__info-container">
					<h3 className="contact-info__header">Our Address</h3>
					<span>alitus</span>
					<span>13 Castle Street</span>
					<span>Eye</span>
					<span>Suffolk</span>
					<span>IP23 7AN</span>
				</div>

				<div className="contact-info__info-container">
					<h3 className="contact-info__header">Enquiries</h3>
					<a href="mailto:info@alitus.co.uk" className="contact-info__link">info@alitus.co.uk</a>
				</div>

				<div className="contact-info__info-container">
					<h3 className="contact-info__header">Give us a call</h3>
					<a href="tel:01379678483" className="contact-info__link">01379 678483</a>
				</div>
			</div>
		</section>
	)
}
