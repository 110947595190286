import React from 'react'

import SEO from '../components/SEO/SEO'
import ContactInfo from '../components/ContactInfo/ContactInfo'
import Map from '../components/Map/Map'
import AnimatedStroke from '../components/AnimatedStroke/AnimatedStroke'

export default function ContactUs({ location }) {

	return (
		<>
			<SEO title="Contact Us" pathname={ location.pathname }/>

			<div className="content-page content-page--has-header content-page--no-margin-bottom">
				<AnimatedStroke pathType={ 'contact-us' } cssClass="content-page__stroke" pixelDelay={ 105 } reverse={ true } />

				<div className="wrapper">
					<div className="content-page__header">
						<h1 className="content-page__heading">Get in touch with us</h1>
					</div>
				</div>

				<ContactInfo />
				<Map />
			</div>
		</>
	)
}